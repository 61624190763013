<template>
  <div>
    <v-row>
      <v-col cols="12" md="3" sm="12">
        <v-card class="mt-4 mx-auto card-chart" max-width="400" elevation="0">
          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              Visitantes: {{ qtyVisitorEscort[0] }}
            </div>
            <div class="subheading font-weight-light"></div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >Hoje</span
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-card class="mt-4 mx-auto card-chart" max-width="400" elevation="0">
          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              Acompanhantes: {{ qtyVisitorEscort[1] }}
            </div>
            <div class="subheading font-weight-light"></div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >Hoje</span
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-card class="mt-4 mx-auto card-chart" max-width="400" elevation="0">
          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              Visitantes registrados hoje: {{ qtyVisitorEscort[2] }}
            </div>
            <div class="subheading font-weight-light"></div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >Hoje</span
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-card class="mt-4 mx-auto card-chart" max-width="400" elevation="0">
          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              Acompanhantes registrados hoje: {{ qtyVisitorEscort[3] }}
            </div>
            <div class="subheading font-weight-light"></div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >Hoje</span
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-col>

    <div>
      <apexchart
        ref="realTimeCharts"
        width="500"
        :type="type"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
    </v-col>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data: () => ({
    value: "",
    qtyVisitorEscort: [],
    type: "bar",
    options: {
      chart: {
        id: "relatorio-dashboard",
      },
      xaxis: {
        categories: ["Visitante(s)", "Acompanhante(s)", "Visitante/dia", "Acompanhante/dia"],
      },
    },
    series: [
      {
        name: "Quantidade",
        data: [],
      },
    ],
  }),
  methods: {
    updateCharts() {
      this.series = [
        {
          data: this.qtyVisitorEscort,
        },
      ];
    },
  },
  async created() {
    await axios.get("/dashboard").then((e) => {
      this.qtyVisitorEscort = [
        e.data.qty_visitor, 
        e.data.qty_escort,
        e.data.visitor_on_day,
        e.data.escort_on_day,
        ];
      this.updateCharts();
    });
  },
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}
</style>
